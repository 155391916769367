import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import ImageSlider from './ImageSlider';
import ImageSlider2 from './ImageSlider2';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import WaitList from './WaitList';
import CarouselComponent from './CarouselComponent';
import gsap from 'gsap';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Marquee from 'react-fast-marquee';

gsap.registerPlugin(ScrollTrigger);

ScrollMagicPluginGsap(ScrollMagic, gsap);

function HomePage() {
  const navigate = useNavigate();

  

  const frameRef = useRef(null); // Reference to hold the image element

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1100);

  // Check screen size on resize
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 1100);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const totalFrames = 90;
    const baseCloudFrontURL = 'https://d27oebqltxtb0s.cloudfront.net/frames/'; // Replace with your CloudFront URL
    const images = [];

    // Load all images from CloudFront in sequence
    for (let i = 1; i <= totalFrames; i++) {
      const paddedIndex = String(i).padStart(4, '0');
      const imageUrl = `${baseCloudFrontURL}frame_${paddedIndex}.jpg`;
      images.push(imageUrl);
      
      // Preload each image
      const img = new Image();
      img.src = imageUrl;
    }
  
    const updateImage = (index) => {
      if (frameRef.current) {
        frameRef.current.src = images[index];
      }
    };
  
    if (frameRef.current) {
      frameRef.current.src = images[0];
    }
  
    const controller = new ScrollMagic.Controller();
  
    new ScrollMagic.Scene({
      triggerElement: '.homepage-form-container',
      triggerHook: 0,
      duration: '40%',
    })
      .on('progress', (e) => {
        const frameIndex = Math.floor(e.progress * (totalFrames - 1));
        updateImage(frameIndex);
      })
      .addTo(controller);
  
    // Animation for desktop overlay text
    if (!isSmallScreen) {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.animated-frame',
          start: 'top top',
          end: '+=500',
          scrub: true,
        }
      })
        .fromTo(
          '.overlay-text p:nth-child(1)',
          { color: 'white' },
          { color: '#68F60C', duration: 0.2 },
          0
        )
        .fromTo(
          '.overlay-text',
          { rotateY: 0, right: "10%" },
          { rotateY: -30, right: "5%", transformOrigin: 'left center', duration: 0.5 },
          0
        );
    } else {
      // Animation for mobile overlay text
      gsap.timeline({
        scrollTrigger: {
          trigger: '.animated-frame',
          start: 'top top',
          end: '+=500',
          scrub: true,
        }
      })
        .fromTo(
          '.small-screen-top-text',
          { rotateY: 0 },
          { rotateY: -30, transformOrigin: 'left center', duration: 0.5 },
          0
        )
        .fromTo(
          '.small-screen-bottom-text',
          { rotateY: 0 },
          { rotateY: -30, transformOrigin: 'left center', duration: 0.5 },
          0
        );
    }
    
    return () => controller.destroy();
  }, [isSmallScreen]);
  


  
  const bubblesData = [
    { header: 'Sync With Dutchie', subtext: ['Partnered Directly with your POS','Live Data, Reported Visually'] },
    { header: 'Control Unlimited TVs', subtext: ['Remotely Edit Your Slideshows', 'Photo & Video Support at no extra cost per TV', 'Advanced Offline Functionality'] },
    { header: 'Bulk Asset Creation', subtext: ['Create and Export Multiple Dealsheets', 'Straight to your TVs in One Click'] },
    { header: 'Cut Hardware Costs', subtext: ['Building a new location has never been cheaper!', 'Pre-made Bundles make us your one stop shop', 'Dont buy before you see our prices'] }
  ];

  const handleEnter = ({ target }) => {
    gsap.fromTo(target, { autoAlpha: 0, x: '-=100' }, { autoAlpha: 1, x: '0', duration: 2 });
  };

  const handleLeave = ({ target }) => {
    gsap.to(target, { autoAlpha: 0, x: '+=100', duration: 2 });
  };

  useEffect(() => {
    const secondaryHeader = document.getElementById('secondary-header');
    const bubblesSection = document.querySelector('.bubbles-section');
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.boundingClientRect.top <= 52 && !entry.isIntersecting) {
          secondaryHeader.classList.add('sticky');
        } else {
          secondaryHeader.classList.remove('sticky');
        }
      },
      {
        rootMargin: `-55px 0px 0px 0px`, // Offset to account for primary header height
        threshold: 0,
      }
    );
  
    if (bubblesSection) observer.observe(bubblesSection);
  
    return () => observer.disconnect();
  }, []);
  
  

  useEffect(() => {
    const controller = new ScrollMagic.Controller();
  
    document.querySelectorAll('.bubble-container').forEach((bubble, index) => {
      const direction = index % 2 === 0 ? '-=' : '+=';
      const timeline = gsap.timeline()
        .fromTo(bubble, { autoAlpha: 0, x: `${direction}88` }, { autoAlpha: 1, x: '0', duration: 1 });
  
      new ScrollMagic.Scene({
        triggerElement: bubble,
        triggerHook: 1,
        reverse: true,
      })
      .setTween(timeline)
      .addTo(controller);
    });

    // Mission Statement Animation
  const missionTimeline = gsap.fromTo('.mission-statement', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1 });
  new ScrollMagic.Scene({
    triggerElement: '.mission-logo-section',
    triggerHook: 0.6, // Trigger at 80% of the viewport height
    offset: '0%', // You can adjust this value as needed
  })
  .setTween(missionTimeline)
  .addTo(controller);

  // Vision Statement Animation
  const visionTimeline = gsap.fromTo('.vision-statement', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1 });
  new ScrollMagic.Scene({
    triggerElement: '.mission-statement',
    triggerHook: 0.6, // Trigger at 80% of the viewport height
    offset: '0%', // You can adjust this value as needed
  })
  .setTween(visionTimeline)
  .addTo(controller);

}, []);

const handleViewPlan = () => {
    navigate('/pricing');
  }
const [isYearly, setIsYearly] = useState(false);

// Setting up individual pricing and features for each plan
const [plans, setPlans] = useState([
    {
        name: 'Enterprise',
        monthlyPrice: '$499',
        yearlyPrice: '$399',
        features: [
            'Advanced Reorder Reporting',
            'Live Customer Queue Line',
            'NextGen Dealsheet Creator',
            { feature: 'Remote Digital Signage', detail: 'unlimited screens' },
            'QR Generator & Tracker',
            'Digital Customer Facing Menu'
        ]
    },
    {
        name: 'Premium',
        monthlyPrice: '$299',
        yearlyPrice: '$199',
        features: [
            'Live Customer Queue Line',
            'NextGen Dealsheet Creator',
            { feature: 'Remote Digital Signage', detail: '5 screens' },
            'QR Generator & Tracker',
            'Digital Customer Facing Menu'
        ]
    },
    {
        name: 'Basic',
        monthlyPrice: '$99',
        yearlyPrice: '$79',
        features: [
            'Digital Customer Facing Menu',
            'QR Generator & Tracker',
            { feature: 'Remote Digital Signage', detail: '2 screens' },
            { feature: 'NextGen Dealsheet Creator', detail: 'LIMITED', limited: true }
        ]
    }
    
]);

  const htmlCode = `
  <div id="mc_embed_shell">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
    #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px; max-width: 95vw; padding: 20px; border-radius:20px;}
    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
       We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://studio.us21.list-manage.com/subscribe/post?u=6db5e789fac0d3cc3bfb8363a&amp;id=7b9b8ae35b&amp;f_id=005be4e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
    <div id="mc_embed_signup_scroll">
    <h2>Dont miss our next wave of Onboarding!</h2>
    <p>And get an EXCLUSIVE DISCOUNT, expected Q1 2024🚀<p>
        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
        <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""><span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span></div>
        <div class="mc-field-group"><label for="mce-COMPANY">Company </label><input type="text" name="COMPANY" class=" text" id="mce-COMPANY" value=""></div>
    <div id="mce-responses" class="clear foot">
        <div class="response" id="mce-error-response" style="display: none;"></div>
        <div class="response" id="mce-success-response" style="display: none;"></div>
    </div>
<div style="position: absolute; left: -5000px;" aria-hidden="true">
    /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
    <input type="text" name="b_6db5e789fac0d3cc3bfb8363a_7b9b8ae35b" tabindex="-1" value="">
</div>
    <div class="optionalParent">
        <div class="clear foot">
            <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Notify Me">
            
        </div>
    </div>
</div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>

  `;



  return (
    
    <div className='home-container'>
      <header className="home-header">
        <div className="home-logo-container">
          <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png" alt="GreenMind Logo" />
        </div>
        <div>
        <button className="home-login-button" onClick={() => window.open('https://mailchi.mp/greenmind/greenmind-landing-page', '_blank')}>
        
          Learn More
        </button>
        <button className="home-login-button" onClick={() => navigate('/login')}>
          Login
        </button>
        </div>
      </header>
      <div className="content">
      
      <div className="background-image-container wl-conts" style={{ position: 'relative' }}>
        <div className='BackgroundTopHalf'></div>
        <div className='BackgroundBottomHalf'></div>
        
      <div className="homepage-form-container">
        <img ref={frameRef} src="" alt="Scrolling sequence" className="animated-frame" />
        <div className="overlay-text">
          <p>SIMPLIFY</p>
          <p>RETAIL.</p>
        </div>
        {isSmallScreen && (
              <>
                <div className="small-screen-top-text">SIMPLIFY RETAIL</div>
                <div className="small-screen-bottom-text">Learn More</div>
              </>
            )}
        <div className="down-container">
          <div className="scroll"></div>
        </div>
      </div>
      </div>
        <div className="mission-logo-section">
            <div className="mission-logo-container">
                <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png" alt="Logo" />
            </div>
            <p className="mission-statement">Trusted By Industry Leaders:</p>
            <div className="home-client-logos-container">
              {["ClinicCropped.png", "DispoCropped.png", "JoyCropped.png", "RushCropped.png", "NirvanaCropped.png", "HighClubCropped.png", "PrimitivCropped.png", "GreenpharmCropped.png", "FCCropped.png"].map((logo, index) => (
                <img key={index} src={`https://d27oebqltxtb0s.cloudfront.net/${logo}`} alt={`Client logo ${index + 1}`} className="home-client-logo" />
              ))}
            </div>
        </div>
        <div className="bubbles-section">
          {bubblesData.map((bubble, index) => (
            <div key={index} className={`bubble-container ${index % 2 === 0 ? 'left' : 'right'}`}>
              <div className="circle">
                <img src={`https://d27oebqltxtb0s.cloudfront.net/homepage-vector-${index + 1}.png`} alt={`Circle ${index + 1}`} />
              </div>
              <div className="text-container">
                <h3 className='bigger-text'>{bubble.header}</h3>
                {bubble.subtext.map((line, lineIndex) => (
                  <p key={lineIndex} className='bullets'>{line}</p>
                ))}
              </div>
              
            </div>
          ))}
        </div>

        <div className="secondary-header" id="secondary-header">
        <Marquee gradient={false} speed={100} loop={0} pauseOnHover={true}>
          Save $20,000 per store without sacrificing quality — View Our Selection Now! &nbsp;&nbsp;&nbsp;
        </Marquee>
          <button onClick={() => window.open('/hardware', '_self')}>View Hardware</button>
        </div>

        <div className="home-customer-feedback">
          <div className="feedback-container">
            <img src="https://lh3.googleusercontent.com/p/AF1QipP96twDKUghvVbXOJzqMctLE6QtHiXwttNN2_dc=s680-w680-h510" alt="Customer" className="feedback-image" />
            <div className="feedback-text">
            <p className="feedback-quote">"Greenmind revolutionized my business! It's essential for smooth operations, saving time and money on payroll and marketing. Unique and indispensable in our industry."</p>
            <p className="feedback-name">⭐⭐⭐⭐⭐</p>
            <p className="feedback-name">- Chris Yermian</p>
            <p className="feedback-title">CEO, Joyology</p>
            </div>
          </div>
        </div>


        <div className='frontend-slide-container'>
            <ImageSlider2 className='homeimageslider' />
            <div className='text-container centered-text-container'>
                <h3 className='bigger-text'>Customer Queue Line</h3>
                <p className='bullets'>Optimize Customer Flow In-Store</p>
                <p className='bullets'>Avoid Corrals, Lines, and Confusion</p>
                <p className='bullets'>Remotely View Your Store Queues</p>
                <p className='bullets'>Customize Visually To Your Brand</p>
                <p className='bullets'>Easy Setup With Your POS</p>
                <p className='bullets'>Fast & Responsive</p>
            </div>
        </div>
        <div className='frontend-slide-container-black'>
            <CarouselComponent />

            <div className='text-container centered-text-container-left'>
                <h3 className='bigger-text'>QR Code Generator & Tracker</h3>
                <p className='bullets'>Centralize Your Campaigns</p>
                <p className='bullets'>Track Your Scans, See Whats Working</p>
                <p className='bullets'>Dynamic Links - Change Destination After Printing</p>
                <p className='bullets'>Perfect for Lighters, Bags, Mailers, Billboards, & More!</p>
                <p className='bullets'>Location Tracking By City</p>
                
            </div>
            
        </div>
        <div className="home-customer-feedback">
          <div className="feedback-container">
            <img src="https://d27oebqltxtb0s.cloudfront.net/RushQueueImg.png" alt="Customer" className="feedback-image" />
            <div className="feedback-text">
            <p className="feedback-quote">"The biggest logistics asset to our company since starting our brand. As an owner-operator of multiple dispensaries, Greenmind has elevated our customer experience on the frontend, and helped our employees on the backend.</p>
            <p className="feedback-name">⭐⭐⭐⭐⭐</p>
            <p className="feedback-name">- Anton M</p>
            <p className="feedback-title">Rush Cannabis</p>
            </div>
          </div>
        </div>
        <div className='frontend-slide-container'>
            <ImageSlider className='homeimageslider' />
            <div className='text-container centered-text-container'>
            <h3 className='bigger-text'>Customer Facing Menu</h3>
                <p className='bullets'>Live Product Menu - Touchscreen Compatable</p>
                <p className='bullets'>Sort Products By Category or Brand</p>
                <p className='bullets'></p>
                <p className='bullets'>Customize Visually To Your Brand</p>
                <p className='bullets'>Easy Kiosk Mode Installation</p>
                <p className='bullets'>Restrict Screen Features While Open</p>
            </div>
            
        </div>

        <div className="home-banner">
          <h2 className="home-banner-heading">Save $15,000 With a 15 Minute Call</h2>
          <img src="https://d27oebqltxtb0s.cloudfront.net/DemoPhoneCall.png" alt="Customer" className="scale-down" />
          <p className="home-banner-subtext">Let our team walk you through it</p>
          <button
            className="home-banner-button"
            onClick={() => window.open('https://calendly.com/d/cphr-gbp-9hw', '_blank')}
          >
            Book Now
          </button>
        </div>

        <div className="home-footer">
          <div className="home-footer-icons">
            <a href="https://www.facebook.com/profile.php?id=61569402114469" target="_blank" rel="noopener noreferrer">
              <div className="home-footer-icon-circle">
                <i className="fab fa-facebook-f"></i>
              </div>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <div className="home-footer-icon-circle">
                <i className="fab fa-linkedin-in"></i>
              </div>
            </a>
            <a href="https://www.instagram.com/greenminddotstudio/" target="_blank" rel="noopener noreferrer">
              <div className="home-footer-icon-circle">
                <i className="fab fa-instagram"></i>
              </div>
            </a>
          </div>
          <div className="home-footer-copyright">
            Greenmind Studio LLC © 2024 <br />
            43200 Garfield Rd, Clinton TWP MI 48038
          </div>
        </div>

        </div>
    </div>
  );
}

export default HomePage;
